/*  src/Title.css  */
.Title {
  box-sizing: border-box;
  width: 99%;
  height: 50%;
  display: grid;
  justify-content: center;
  padding: 0em;
  margin-bottom: 0em;
  background-color: #FF28BC;

}

.h1 {font-size: 18rem}