@font-face {
  font-family: "utopia_seriff";
  src: local("utopia_seriff"), url("./fonts/utopia_seriff.ttf");
}


@media only screen and (max-width: 800px) {
  .photo {
    width: 90vw;
    height: auto;
    object-fit: contain;
    border-radius: 20px;
    padding: 1rem;
    margin: 0 auto;
    justify-content: center;
  }
}
@media only screen and (min-width: 801px) {
  .photo {
    width: 85vw;
    height: auto;
    object-fit: contain;
    border-radius: 20px;
    margin: 0 auto;
    justify-content: center;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    height: 80%;
    width: 98%;
    margin: 2em 1em;
    /* font-size: 2em; */
    /*display: grid;*/
    /*grid-template-rows: 1fr 3fr 1fr auto;*/
    /*grid-template-columns: 1fr 1fr 1fr;*/
  }
}

@media only screen and (min-width: 801px) {
  .container {
    height: 80%;
    width: 95%;
    margin: 5em 5em;

    /*display: grid;*/
    /*grid-template-rows: 1fr 3fr 1fr auto;*/
    /*grid-template-columns: 1fr 1fr 1fr;*/
  }
}
.content {
  /*grid-row: 2/3;*/
  /*grid-column: 1/3;*/
  height: 60%;
  width: 99%;
  font-size: 12rem;
}
.title {
  height: 20%;
  width: 100%;
  font-size: 12;
  /*grid-row: 1;*/
  /*grid-column: 1/3;*/
}

.other-guy {
  height: 200px;
  width: 95%;
  margin: 14em 1em 1em 1em;

  /*grid-row: 4;*/
  /*grid-column: 1/3;*/
background-color: #FF00B7;
}
.other-guy2 {
  height: 200px;
  width: 95%;
  margin: 34em 1em 1em 1em;

  /*grid-row: 4;*/
  /*grid-column: 1/3;*/
background-color: #00EAFF;
}
.footer {
  grid-row: 5/-1;
  grid-column: 1/3;
}
body {
  background-image: url("./assets/zxylogo.JPG");
  background-repeat: space repeat;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Resizer {
  background: #000;
  opacity: 0.2;

  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 10px solid rgba(255, 255, 255, 0);
  border-right: 10px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 25px solid rgba(0, 0, 0, 0.5);
  border-right: 25px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: black;
}

h1 {
  font-family: "utopia_seriff", utopia_seriff, "Courier New";
  font-size: 1.5rem;
  line-height: 1.42857143;
  color: #000;
}

.header {
  color: blue;
  height: "25%";
  background: "green";
  border: "50%";
}

.header2 {
  font-size: 1rem;
}

h2 {
  font-family: "utopia_seriff", helvetica, serif;
  font-size: 1.25rem;
  line-height: 2.42857143;
}

p {
  font-family: "utopia_seriff", helvetica, serif;
  font-size: 1rem;
  line-height: 2.42857143;
}
