.Footer {
  display: block;
  box-sizing: border-box;
  width: 80vw;
  height: 50vw;


  /*justify-content: default;*/

  margin: 5em 10em;
  background-color: #00EAFF;
  bottom: auto;
  font: "utopia_seriff";
  align-self: end;
  align-items: last;
}
