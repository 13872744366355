.Resizer {
  display: block;
  background: #000;
  opacity: 0.2;
  z-index: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  display: block;
  height: 110px;
  margin: -500px 100px;
  border-top: 1rem solid rgba(255, 255, 255, 0);
  border-bottom: 1rem solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;

}

.Resizer.horizontal:hover {
  border-top: 50px solid rgba(0, 0, 0, 0.5);
  border-bottom: 50px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  display: block;
  width: 12rem;
  margin: 100rem -1rem;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
